.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&__gif-box {
		display: flex;
		justify-content: center;
		&--img {
			max-width: 70%;
		}
	}

	&__contact {
		display: flex;
		flex-direction: column;
		height: 50%;
		width: 40%;
		border: 3px $color-grey solid;
		margin: 1%;

		@include respond(phone) {
            width: 90%;
		}
	}

	&__bottom {
		background-color: $color-green-primary;
		width: 100%;
		&__image-box {
			width: 20%;
			margin: 0 auto;
			&--img {
				max-width: 15%;
				margin: 2%;
			}
		}

		&__copyright {
		}
	}
}
