.blog {
	width: 70%;
	margin: 5% auto;
	border: 1px $color-grey solid;
	padding: 5%;

	@include respond(tab-land) {
		width: 90%;
	}
	&__post {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		&--img {
			max-width: 60%;

			@include respond(tab-land) {
				max-width: 80%;
			}
		}
	}
}
