// COMPONENT: Navbar
.navbar {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 12rem;
    background-color: $color-green-primary;

    &__logo {
        width: 15%;
        position: relative;
        z-index: 600;
        top: 3%;
        left: 0;
        transform: rotateZ(-.5deg);
        &--img {
            max-width: 100%;
            
        }
    }

    &__header {
        color: $color-white;
        font-size: 2.4rem;
        margin-right: 2%;
    }

    &__list-box {
        animation: NavRight .8s;
    }

    &__list {
        display: flex;
        
    }

    &__item {
        display: inline-block;
		padding: 1rem;
    }

    &__link {
        text-decoration: none;
		color: $color-white;
		font-size: 2rem;
		transition: all 0.4s;
        &:hover {
			color: $color-green-dark;
		}
    }

    &__desktop {
		@include respond(tab-port) {
			display: none;
		}
    }
    
    &-mobile {
		display: none;

		@include respond(tab-port) {
			display: inherit;
			position: absolute;
			cursor: pointer;
		}
	}
}


// THIS IS FOR NAVBAR MOBILE STYLES
.navbar-mobile {
	position: fixed;
	z-index: 400;
	&__open {
		background-color: $color-green-primary;
		position: fixed;
		width: 70%;
		height: 110vh;
		z-index: 400;
		animation: BounceRight .4s ease-in;
	}
	
	&__btn {
		position: fixed;
		margin: 2%;
		padding: 2%;
		border-radius: 50%;
		font-size: 3rem;
		z-index: 500;
		transition: all .2s;

		&--open {
			color: $color-white;
		}
	}

	&__list-box {
		margin: 25% 0;	
	}

	&__item {
		display: block;
		padding: 1rem;
	}

	&__link {
		text-decoration: none;
		color: $color-white;
		font-size: 3rem;
		transition: all 0.2s;

		&:hover {
			color: $color-green-dark;
		}
	}
}


// .active comes from react-router-dom and the navbar link that is active gets this color
.active {
	color: $color-black;
}