//COMPONENT: Shop
.shop {
	animation: 0.5s ScrollLeft ease-out;
}

// COMPONENT: ShopProducts
.shop-products {
	display: flex;
	justify-content: center;
	@include respond(phone) {
		flex-direction: column;
	}
}

// COMPONENT: Product
.product {
	width: 20%;
	margin: 2%;
	position: relative;
	cursor: pointer;

	@include respond(phone) {
		width: 80%;
		margin: 0 auto 3rem;
	}

	&__img-box {
		width: 100%;
		&--img {
			max-width: 100%;
		}

		&:hover .product__img-box--back {
			display: inherit;
		}

		&--back {
			display: none;
			position: absolute;
			height: 100%;
			top: 0;
			z-index: 100;
			overflow: scroll;
		}
	}

	&__text-box {
		&__separator {
			border-top: 1px solid $color-black;
			width: 10%;
			margin: 0 auto;
		}
	}
}

// COMPONENT: Detail
.detail {
	display: flex;
	width: 80%;
	margin: 5% auto;

	@include respond(phone) {
		flex-direction: column;
		align-items: center;
	}
	// left side
	&__img-side {
		margin-right: 5%;
		width: 100%;

		@include respond(phone) {
			margin-bottom: 3rem;
		}
		&__img-box {
			width: 100%;
			&--img {
				max-width: 100%;
			}
		}

		&__thumbnail {
			display: flex;
			width: 10%;
			margin: 2%;
			cursor: pointer;
			@include respond(phone) {
				width: 20%;
			}
			&--img {
				max-width: 100%;

				&__active-thumbnail {
					border: $color-green-dark solid 2px;
				}
			}
		}

		&__text-box {
		}
	}

	// right side
	&__cart-side {
		width: 70%;

		@include respond(phone) {
			width: 90%;
			text-align: center;
		}

		&--mobile-link {
			display: none;
			@include respond(tab-port) {
				display: inherit;
			}
		}

		&__quantity {
		}

		&__instructions-box {
		}

		&__refund-box {
		}
	}
}
