.cart {
	position: relative;
}

// COMPONENT: CartColumns
.cart-columns {
	border-bottom: black 1px solid;
	display: flex;
	width: 80%;
	margin: auto;
	justify-content: flex-end;
	&__column {
		margin: 0 4%;
	}

	@include respond(phone){
		display: none;
	}
}

.cart-item {
	display: flex;
	width: 80%;
	margin: auto;
	justify-content: flex-end;

	@include respond(phone){
		flex-direction: column;
		margin-bottom: 5%;
		border-bottom: 1px solid $color-grey;
	}

	&__column {
		margin: 3% 3%;
		@include respond(phone){
			margin: 2% 2%;
		}
		&--custom {
			display: flex;
		}

		&--img {
			max-width: 20%;
			@include respond(phone){
				max-width: 50%;
			};
		}

		&__btn-box {
			display: flex;
			flex-direction: column;
		}

		&--mobile {
			display: none;
			@include respond(phone) {
				display: initial;
			}
		}

		&--desktop {
			@include respond(phone) {
				display: none;
			}
		}
	}
}

// COMPONENT: CartIcon
.cart-icon {
	position: absolute;
	right: 5%;
	top: 30%;
	cursor: pointer;
	&__icon {
		font-size: 3rem;
		padding: 0.5rem 1rem;
		border-radius: 50%;
		background-color: $color-white;
		position: relative;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
		transition: all 0.2s;

		&:hover {
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
		}
	}

	&__number {
	}
}

// COMPONENT: CartTotal
.cart-total {
	width: 30%;
	position: relative;
	right: -60%;
	@include respond(phone){
		width: 75%;
		position: initial;
		margin: auto;
	}
	&__subtotal {
		display: flex;
		justify-content: space-between;
	}

	&__shipping {
		display: flex;
		justify-content: space-between;
	}

	&__tax {
		display: flex;
		justify-content: space-between;
	}

	&__total {
		display: flex;
		justify-content: space-between;
	}

	&__paypal {
		
	}
}
