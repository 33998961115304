.btn {
	&,
	&:link,
	&:visited {
		text-decoration: none;
		font-size: $default-font-size;
		color: $color-white;
		display: inline-block;
		padding: 0;
		margin: 2%;
		background-color: $color-green-dark;
        cursor: pointer;
        transition: all .3s;
	}

	&--light:hover {
		background-color: $color-green-light;
	}

	&--sm {
		padding: 0 2rem;
	}

	&--add-to-cart {
		width: 100%;
		padding: 2%;
		border-radius: 25px;
		color: $color-black;
		background-color: $color-green-primary;
		&:hover {
			background-color: $color-green-light;
		}
	}

	&--lg {
	}
}
