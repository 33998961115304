
.u-center-text { text-align: center }

.u-margin-top-sm { margin-top: 2rem; }
.u-margin-bottom-sm { margin-bottom: 2rem; }

.u-margin-bottom-md { margin-bottom: 4.5rem }


.u-block-el { display: inline-block }

.u-underline { text-decoration: underline; cursor: pointer;}