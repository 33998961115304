// COMPONENT: Home
.home {
	animation: 0.5s ScrollLeft ease-out;
	position: relative;
}

// COMPONENT: Mission
.mission {
	padding: 2%;
	background-color: $color-green-primary;
	&__separate {
		border-top: 3px solid $color-white;
		width: 5%;
		margin: 0 auto;
	}
}

// COMPONENT: Why
.why {
	display: flex;

	@include respond(phone) {
		flex-direction: column;
	}
}

// COMPONENT: WCard
.wcard {
	padding: 4%;
	width: 25%;
	line-height: 1.6;

	@include respond(phone) {
		width: 100%;
	}
	&--black {
		background-color: $color-black;
		color: $color-white;
	}

	&--grey {
		background-color: $color-grey;
		color: $color-white;
	}

	&--grey-light {
		background-color: $color-grey-light;
		color: $color-black;
	}

	&--green {
		background-color: $color-green-light;
		color: $color-black;
	}
}

// COMPONENT: Contact
.contact {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 5%;
	&__form-box {
		&--input {
			display: flex;
			margin: 0 auto;
			width: 70%;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;
		}
	}
}

// COMPONENT: Carousel
.carousel {
	position: relative;
	z-index: 10;
	padding: 0;
	background-color: $color-green-primary;
	&__header {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-shadow: 0px 4px 1px darken($color-grey-light, 35%);
	}

	&--img {
		width: 100%;
		object-fit: cover;
		max-height: 50rem;
		animation: ScrollLeft 0.5s ease-in;
	}

	&__dot-display {
		display: flex;
		position: absolute;
		left: 50%;
		top: 90%;
		transform: translateX(-50%);
		&--dot {
			margin: 5%;
			z-index: 400;
			width: 1.5rem;
			height: 1rem;
			border-radius: 50%;
			border-color: transparent;
			outline: none;
			background-color: rgba($color-black, 0.4);

			&--active {
				background-color: rgba($color-white, 0.8);
			}
		}
	}
}

// COMPONENT: AsSeenIn
.as-seen-in {
	width: 100%;
	height: 100%;
	&__text-box {
	}

	&__img-box {
		display: flex;
		justify-content: center;

		&--img {
			margin: 0 5%;
			max-width: 100%;
			height: 15rem;
		}
	}
}

// COMPONENT: HowItStarted
.how-it-started {
	display: flex;
	height: 100vh;
	margin-top: 3%;
	@include respond(phone) {
		flex-direction: column-reverse;
		align-items: center;
	}

	&__img-box {
		width: 50%;
		height: 100%;
		background-image: url('../../assets/images/turtle-drawing.png');
		background-size: cover;
		background-position: top center;

		@include respond(phone) {
			display: none;
		}
	}

	&__text-box {
		background-color: $color-green-dark;
		width: 50%;
		padding: 2%;

		@include respond(phone) {
			width: 100%;
			text-align: center;
		}
	}
}
