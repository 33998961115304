// COMPONENT: About
.about {
	margin: 2% 0% 5%;
	animation: 0.5s ScrollLeft ease-out;
	&__members {
		display: flex;
		justify-content: space-around;
	}
}

// COMPONENT: Member
.member {
	width: 30%;

	&__separate {
		border-top: 3px solid $color-green-dark;
		width: 15%;
		margin: 0 auto;
	}

	&__img-box {
		position: relative;
        height: 100%;
		&--img {
			max-width: 100%;
			z-index: 40;
		}

		&:hover .member__img-box--description {
			display: inherit;
		}

		&--description {
			background-color: $color-green-light;
			display: none;
			position: absolute;
			height: 100%;
			z-index: 100;
			padding: 10% 10%;
            overflow: scroll;
            
            &__text-box {
                animation: ScrollUp .4s ease-out;
            }
		}
	}
}
