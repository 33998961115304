body {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	line-height: 1.875;
}

@font-face {
	font-family: 'Grobold';
	src: url('../../assets/font/GROBOLD.ttf');
	font-style: normal;
	font-weight: 100;
}


// Anything with this class will be grobold
.grobold {
	font-family: 'Grobold';
}

// HEADINGS 
.heading-primary {
	text-transform: uppercase;
	font-size: 4rem;
}

.heading-secondary {
	font-size: 3.8rem;
	&--lg {
		font-size: 5rem;
	}

	&--mobile {
		@include respond(phone){
			font-size: 3.3rem;
    	}
	}
}

.heading-tertiary {
	font-size: 3.3rem;
}

.faq-header {
	text-align: start;
	background-color: $color-green-dark;
	display: inline-block;
	font-size: $default-font-size;
	width: 100%;
	padding-left: 2%;
}

// PARAGRAPHS
.paragraph {
	font-size: $default-font-size;
	line-height: 1.7;
	&--sm {
		font-size: 1.5rem;
	}

	&--bold {
		font-weight: 600;
	}
}

// TEXT COLORS 
.white {
	color: $color-white;
}

.red {
	color: $color-red;
}

.green {
	color: $color-green-primary;
}

.green-dark {
	color: $color-green-dark;
}

.green-light {
	color: $color-green-light;
}

.grey {
	color: $color-grey;
}

.grey-light {
	color: $color-grey-light;
}

