.input {
	border: 1px solid $color-grey;
	background-color: $color-white;
    width: 40%;
    margin: 2% 0;
	padding: 1rem;
    outline: none;
    resize: none;
	&::placeholder {
		color: $color-grey;
        font-size: 1.7rem;
        font-weight: 700;
	}

	&--footer {
		border: 1px solid $color-grey;
		background-color: $color-grey-light;
		margin: 2%;
		padding: 1rem;
		outline: none;
		&::placeholder {
			color: $color-black;
			font-size: 1.6rem;
		}
    }
    
    &--text-area {
        width: 90%;
    }
}


.quantity {
	font-size: $default-font-size;
	border: 1px solid black;
	padding: 0 .5rem;
	cursor: pointer;
	transition: all .2s;
	&:hover {
		background-color: $color-black;
		color: $color-white;
	}
}