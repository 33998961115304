*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 62.5%; // default font size is 16px. 62.5% is 10px making 1rem === 10px
	@include respond(tab-land) {
		// width < 1200?
		font-size: 56.25%; // 1 rem = 9px, 9/16 = 56.25%
	}

	@include respond(tab-port) {
		// width < 900?
		font-size: 50%; // 1 rem = 8px, 8/16 = 50%
	}

	@include respond(big-desktop) {
		font-size: 75%; // 1 rem = 12px, 12/16 = 75%
	}
}


// Anchor tag link styling
.link {
	text-decoration: none;
	color: $color-black;
	font-size: 2rem;
	transition: all .2s;
	&:hover {
		color: $color-green-dark;
	}
}

body {
	box-sizing: border-box;
}


// TO ENSURE THE YOUTUBE VIDEO FITS TO EACH PAGE
iframe { 
    max-width: 100%;
	padding: 5%;
	@include respond(tab-port){
		height: 40rem;
	}
}